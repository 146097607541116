.calendar-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .header-row, .user-row {
    display: flex;
  }
  
  .header-cell, .day-cell {
    /* border: 1px solid #ccc; */
    padding: 10px;
    /* width: 60px; */
    text-align: center;
    user-select: none;
  }
  
  .empty-cell {
    width: 100px;
  }
  
  .weekend {
    color: red;
  }
  